import React from 'react';
import {
  Radio as MuiRadio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
} from '@material-ui/core';

const Radio = ({ register, setValue, name, label, options, defaultValue }) => {
  const [radio, setRadio] = React.useState(defaultValue);

  React.useEffect(() => {
    register({ name }); // custom register react-select
  }, [name, register]);

  const handleChange = event => {
    setValue(name, event.target.value, true);
    setRadio(event.target.value);
  };

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup
        row
        aria-label={label}
        name={name}
        value={radio}
        onChange={handleChange}
      >
        {options.map(option => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<MuiRadio />}
            label={option.label}
            labelPlacement="start"
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
export default Radio;
