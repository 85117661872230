import React, { useState } from 'react';
import { DateTimePicker as MuiDateTimePicker } from '@material-ui/pickers';
import styled from 'styled-components';

const DateTimePicker = styled(MuiDateTimePicker)`
  & fieldset {
    font-size: 1rem;
  }
  & label {
    font-size: 1rem;
  }
  & input {
    color: ${props => props.theme.palette.primary.light};
  }
`;

const Picker = ({ name, label, register, getValues, setValue }) => {
  const [pickerValue, setPickerValue] = useState(new Date());

  React.useEffect(() => {
    register({ name });
  }, [name, register]);

  const getDateDifference = (date1, date2) => {
    const timeDiff = Math.abs(date2.getTime() - date1.getTime());
    const diffDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const handleChange = date => {
    let newDate = date;

    const currentDate = new Date();
    const departureDate =
      name === 'request.departureDateTime'
        ? newDate
        : new Date(getValues('request.departureDateTime'));

    if (name === 'request.returnDateTime') {
      if (newDate < departureDate) {
        newDate = departureDate;
      }
    }
    const returnDate =
      name === 'request.returnDateTime'
        ? newDate
        : new Date(getValues('request.returnDateTime'));

    console.log(`departure date: ${departureDate}`);
    console.log(`return date: ${returnDate}`);

    if (newDate < currentDate) {
      newDate = currentDate;
    }

    if (returnDate >= departureDate) {
      const days = getDateDifference(departureDate, returnDate);
      if (days < 1) {
        setValue('request.numberOfDays', 1);
        setValue('request.numberOfNights', 0);
      } else {
        const nights = days - 1 < 0 ? 0 : days - 1;
        setValue('request.numberOfDays', days);
        setValue('request.numberOfNights', nights);
      }
    }

    setValue(name, newDate.toLocaleString('en-us'));
    setPickerValue(newDate);
  };

  return (
    <DateTimePicker
      maxDate={new Date().setFullYear(new Date().getFullYear() + 2)} // Max date 2 years into the future.
      disablePast
      autoOk
      minutesStep={15}
      fullWidth
      size="small"
      variant="inline"
      color="primary"
      inputVariant="outlined"
      label={label}
      value={pickerValue}
      onChange={date => handleChange(date)}
    />
  );
};
export default Picker;
