import React from 'react';
import { Grid as MuiGrid, Typography } from '@material-ui/core';
import styled from 'styled-components';
import Radio from '../fields/radio';
import TextField from '../fields/TextFields';

const Error = styled.small`
  margin: 0;
  padding: 0;
  color: red;
`;

const Grid = styled(MuiGrid)`
  padding: 0.5rem;
  width: 100%;
`;

const MainContainer = styled(Grid)`
  background: white;
  margin: 1rem 0;
  width: 90%;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25), 0px -4px 6px rgba(0, 0, 0, 0.25);
`;

const ContactDetails = ({ register, setValue, control, errors }) => (
  <MainContainer item xs={12} sm={12} md={8} container justify="center">
    {/* Contact */}
    <Grid item sm={12} container justify="center">
      <Grid item xs={12}>
        <Typography variant="h4">Contact Information</Typography>
      </Grid>
      <Grid item xs={6} sm={6}>
        <TextField
          // required
          label="First Name"
          type="text"
          defaultValue=""
          name="client.firstName"
          register={register}
          control={control}
          rules={{ maxLength: 100, required: true }}
        />
        {errors?.client?.firstName?.type === 'required' && (
          <Error>First name is required</Error>
        )}
        {errors?.client?.firstName?.type === 'maxLength' && (
          <Error>First name is limited to 100 characters or less</Error>
        )}
      </Grid>
      <Grid item xs={6} sm={6}>
        <TextField
          label="Last Name"
          name="client.lastName"
          type="text"
          defaultValue=""
          register={register}
          control={control}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label="Email Address"
          name="client.email"
          type="email"
          defaultValue=""
          register={register}
          control={control}
          rules={{ maxLength: 100, required: true }}
        />
        {errors?.client?.firstName?.type === 'required' && (
          <Error>Email is required</Error>
        )}
        {errors?.client?.firstName?.type === 'maxLength' && (
          <Error>Email is limited to 100 characters or less</Error>
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          size="small"
          label="Phone Number"
          color="primary"
          type="text"
          inputRef={register}
          name="client.phoneNumber"
          defaultValue=""
          variant="outlined"
          control={control}
          rules={{ maxLength: 15, required: true }}
        />
        {errors?.client?.firstName?.type === 'required' && (
          <Error>Phone number is required</Error>
        )}
        {errors?.client?.firstName?.type === 'maxLength' && (
          <Error>Phone number is limited to 15 characters or less</Error>
        )}
      </Grid>
      {/* Address */}
      <Grid item xs={12}>
        <Typography variant="h5">Address</Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Address"
          name="client.address.address1"
          type="text"
          multiline
          rowsMax={5}
          defaultValue=""
          register={register}
          control={control}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <TextField
          label="City"
          name="client.address.city"
          type="text"
          defaultValue=""
          register={register}
          control={control}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <TextField
          name="client.address.province"
          label="Province"
          type="text"
          defaultValue="Ontario"
          register={register}
          control={control}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <TextField
          label="Postal Code"
          name="client.address.postalCode"
          type="text"
          defaultValue=""
          register={register}
          control={control}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <TextField
          label="Country"
          name="client.address.country"
          type="text"
          defaultValue="Canada"
          register={register}
          control={control}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Radio
          register={register}
          setValue={setValue}
          name="client.preferredContactMethod"
          label="Preferred Contact Method"
          defaultValue="email"
          options={[
            { value: 'email', label: 'Email' },
            { value: 'phone', label: 'Phone' },
          ]}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Radio
          register={register}
          setValue={setValue}
          name="client.existingCustomer"
          label="Existing Customer"
          defaultValue="no"
          options={[
            { value: 'yes', label: 'Yes' },
            { value: 'no', label: 'No' },
          ]}
        />
      </Grid>
    </Grid>
  </MainContainer>
);
export default ContactDetails;
