import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Grid as MuiGrid,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import styled from 'styled-components';
import { FaMoon, FaKey, FaWalking } from 'react-icons/fa';
import { MdPets } from 'react-icons/md';
import Picker from '../fields/datePicker';
import TextField from '../fields/TextFields';
import Visits from './visits';
import Overnights from './overnights';
import Keys from './keyHandling';
import AdditionalAnimals from './additionalAnimals';
import ContactDetails from './contactDetails';

const FormContainer = styled(MuiGrid)`
  background-color: transparent;
`;

const Grid = styled(MuiGrid)`
  padding: 0.5rem;
  width: 100%;
`;

const Error = styled.small`
  margin: 0;
  padding: 0;
  color: red;
`;

const Warning = styled(Typography)`
  color: ${props => props.theme.palette.warn.main};
`;

const MainContainer = styled(Grid)`
  background: white;
  margin: 1rem 0;
  width: 90%;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25), 0px -4px 6px rgba(0, 0, 0, 0.25);
`;

const Small = styled(Typography)`
  /* color: grey; */
  padding-bottom: 0.5rem;
`;

const SubTotal = styled(Typography)`
  font-size: 0.75rem;
  color: ${props => props.theme.palette.primary.main};

  & small {
    font-size: 0.75rem;
    color: ${props => props.theme.palette.secondary.main};
  }
`;

const FieldSet = styled.fieldset`
  margin-bottom: 1rem;
  border-radius: 5px;
  border-color: rgba(0, 0, 0, 0.5);
`;
const KeysFieldSet = styled(FieldSet)`
  border-color: #f9a01b;
  background: #fef5e8;
`;

const defaultValues = {
  client: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    address: {
      address1: '',
      city: '',
      province: 'Ontario',
      postalCode: '',
      country: 'Canada',
    },
    preferredContactMethod: 'email',
    existingCustomer: 'no',
  },
  request: {
    visits: [
      {
        visitsPerDay: '0',
        numberOfDays: '1',
        total: 0,
        visitLength: '15 Minute',
      },
      {
        visitsPerDay: '0',
        numberOfDays: '1',
        total: 0,
        visitLength: '30 Minute',
      },
      {
        visitsPerDay: '0',
        numberOfDays: '1',
        total: 0,
        visitLength: '45 Minute',
      },
      {
        visitsPerDay: '0',
        numberOfDays: '1',
        total: 0,
        visitLength: '60 Minute',
      },
    ],
    overnights: {
      numberOfNights: '0',
      total: 0,
    },
    additionalAnimals: {
      numberOfAnimals: '0',
      total: 0,
    },
    departureDateTime: new Date(),
    returnDateTime: new Date(),
    numberOfNights: 0,
    numberOfDays: 1,
    keys: {
      requested: 'no',
    },
    keyHandling: {
      total: 0,
    },
    requestNotes: '',
    subTotal: 0,
    tax: 0,
    grandTotal: 0,
  },
};

const Form = () => {
  const [open, setOpen] = useState(false);
  const [total, setTotal] = useState(0);
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    errors,
    reset,
  } = useForm({ defaultValues });

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = () => {
    fetch('https://temp.yellowalbatross.ca/submit/5ebd90f228d5cb0001371a96/request-form', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(getValues({ nest: true })),
    });
    reset(defaultValues);
    setValue(`request.keys.requested`, 'no');
    setOpen(true);
  };
  const visitOptions = [
    {
      cost: 22.25,
      name: '15Minute',
      label: '15 Minute',
    },
    {
      cost: 34.25,
      name: '30Minute',
      label: '30 Minute',
    },
    {
      cost: 42.50,
      name: '60Minute',
      label: '45 Minute',
    },
    {
      cost: 50.0,
      name: '60Minute',
      label: '60 Minute',
    },
  ];

  const updateFormTotal = () => {
    let estimateSubtotal = 0;
    const overnightsTotal = getValues(`request.overnights.total`);
    const AdditionalAnimalsTotal = getValues(`request.additionalAnimals.total`);
    const keysTotal = getValues(`request.keyHandling.total`);
    visitOptions.forEach((visit, visitIndex) => {
      const visitTotal = getValues(`request.visits[${visitIndex}].total`);
      estimateSubtotal += visitTotal || 0;
    });
    estimateSubtotal += overnightsTotal > 0 ? overnightsTotal : 0;
    estimateSubtotal += AdditionalAnimalsTotal > 0 ? AdditionalAnimalsTotal : 0;
    estimateSubtotal += keysTotal > 0 ? keysTotal : 0;
    setTotal(estimateSubtotal);
    setValue('request.subTotal', estimateSubtotal);
    setValue('request.tax', (estimateSubtotal * 0.13).toFixed(2));
    setValue('request.grandTotal', (estimateSubtotal * 1.13).toFixed(2));
  };

  React.useEffect(() => {
    register({ name: `request.subTotal`, type: 'custom' });
    register({ name: `request.tax`, type: 'custom' });
    register({ name: `request.grandTotal`, type: 'custom' });
  }, [register]);

  return (
    <FormContainer
      component="form"
      container
      justify="center"
      onSubmit={handleSubmit(onSubmit)}
    >
      <ContactDetails
        register={register}
        control={control}
        setValue={setValue}
        errors={errors}
      />
      <MainContainer item xs={12} md={8} container justify="center">
        <Grid item sm={12} container justify="center">
          <Grid item xs={12} component={Typography} variant="h4">
            Request Information
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Picker
              name="request.departureDateTime"
              label="Departure Date & Time:"
              register={register}
              getValues={getValues}
              setValue={setValue}
              defaultValue={new Date()}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Picker
              name="request.returnDateTime"
              label="Return Date & Time:"
              register={register}
              getValues={getValues}
              setValue={setValue}
              defaultValue={new Date()}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <TextField
              label="Nights"
              type="number"
              defaultValue="0"
              name="request.numberOfNights"
              register={register}
              control={control}
              rules={{ min: 0 }}
              inputProps={{ min: 0 }}
            />
            {errors?.request?.numberOfNights?.type === 'min' && (
              <Error>Number of nights cannot be a negative number</Error>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <TextField
              label="Days"
              type="number"
              defaultValue="1"
              name="request.numberOfDays"
              register={register}
              control={control}
              rules={{ min: 1 }}
              inputProps={{ min: 1 }}
            />
            {errors?.request?.numberOfDays?.type === 'min' && (
              <Error>Number of days must be greater then 0</Error>
            )}
          </Grid>
          <Grid item xs={12} component={FieldSet}>
            <Typography variant="h6" component="legend">
              <FaWalking /> Check-Ins/Dog Walks
            </Typography>
            <Small>
              <small>
                Check-ins/dog walks are blocks of time in which we provide
                service at your home. Preparation (e.g. putting winter
                coat/boots on your dog before departing) is factored into the
                scheduled time. Please note that our 15 minute check-ins are
                only offered in certain parts of the city - for additional
                information, please get in touch with us via e-mail or call us
                and ask about our 15 minute check-ins.
              </small>
            </Small>
            {visitOptions.map((option, index) => (
              <Visits
                key={index}
                option={option}
                index={index}
                register={register}
                getValues={getValues}
                setValue={setValue}
                updateFormTotal={updateFormTotal}
                errors={errors}
              />
            ))}
          </Grid>
          <Grid item xs={12} component={FieldSet}>
            <Typography variant="h6" component="legend">
              <FaMoon /> Overnights
            </Typography>
            <Typography variant="caption">
              <Small>
                <small>
                  Overnight care is $145.00/night, which guarantees a coverage of
                  10 hours over a 24-hour period + a 30-minute check-in for dinner.
                  This is an all-inclusive rate
                  (medication, feedings, walks, etc). Any additional time
                  requirements (e.g. a daily mid-day check-in, late check-in on
                  return date, etc.) will be supplemental to the overnight
                  charge and should be detailed in the Notes field below.
                </small>
              </Small>
            </Typography>
            <Overnights
              cost="145"
              register={register}
              getValues={getValues}
              setValue={setValue}
              updateFormTotal={updateFormTotal}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} component={FieldSet}>
            <Typography variant="h6" component="legend">
              <MdPets /> Additional Animals
            </Typography>

            <Typography variant="caption">
              <Small>
                <small>Have more then one pet? No problem!</small>
              </Small>
            </Typography>
            <AdditionalAnimals
              cost="80"
              register={register}
              getValues={getValues}
              setValue={setValue}
              updateFormTotal={updateFormTotal}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} component={KeysFieldSet}>
            <Typography variant="h6" component="legend">
              <FaKey /> Key Handling
            </Typography>
            <Small component="small">
              <small>
                Note: There is a $16.50 fee for key pickups and returns for
                future service (pickup at first visit with staff is free of
                charge). You can leave the key on-file with us for no additional
                charge.
              </small>
            </Small>
            <Keys
              cost="16.50"
              register={register}
              getValues={getValues}
              setValue={setValue}
              updateFormTotal={updateFormTotal}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              // required
              label="Request Notes"
              name="request.requestNotes"
              type="text"
              multiline
              rowsMax={5}
              defaultValue=""
              register={register}
              control={control}
              rules={{ maxLength: 1000 }}
            />
            {errors?.request?.requestNotes?.type === 'maxLength' && (
              <Error>Request notes limited to 1000 characters or less</Error>
            )}
          </Grid>
        </Grid>
      </MainContainer>

      <MainContainer item xs={12} sm={12} md={5} container justify="center">
        <Grid item sm={12} container justify="center">
          <Grid item xs={12}>
            <Typography variant="h5">Your Estimate</Typography>
            <Small variant="caption" component="small">
              NOTE: the following estimate is an estimated cost for your desired
              booking. Any number of factors may change the final price. This
              request form is not the final confirmation of your booking, nor
              does it commit you to anything. You may back away at any time
              before a deposit is taken with no consequences. We will provide an
              invoice for the total amount once we have finalized the booking
              details with you.
            </Small>
            <Grid>
              {/* Visits Estimates Summary */}
              {visitOptions.map((option, index) => {
                setValue(`request.visits[${index}].visitLength`, option.label);
                const visitTotal = getValues(`request.visits[${index}].total`);
                return visitTotal > 0 ? (
                  <MuiGrid
                    key={index}
                    item
                    xs={12}
                    component={SubTotal}
                    align="right"
                  >
                    {`Check-Ins/Dog Walks(${option.label}s)`}:
                    <small>{` $${visitTotal.toFixed(2)}`}</small>
                  </MuiGrid>
                ) : (
                  ''
                );
              })}
              {/* Overnights Estimates */}
              {getValues(`request.overnights.total`) > 0 ? (
                <MuiGrid
                  item
                  xs={12}
                  component={SubTotal}
                  color="primary"
                  align="right"
                >
                  Overnights - Service:
                  <small>{` $${getValues(`request.overnights.total`).toFixed(
                    2
                  )}`}</small>
                </MuiGrid>
              ) : (
                ''
              )}
              {getValues(`request.additionalAnimals.total`) > 0 ? (
                <MuiGrid item xs={12} component={SubTotal} align="right">
                  Additional Animals:
                  <small>{` $${getValues(
                    `request.additionalAnimals.total`
                  ).toFixed(2)}`}</small>
                </MuiGrid>
              ) : (
                ''
              )}
              {/* Key Handling Estimates Summary */}
              {getValues(`request.keyHandling.total`) > 0 ? (
                <MuiGrid item xs={12} component={SubTotal} align="right">
                  Key Service - Service:
                  <small>
                    {` $${getValues(`request.keyHandling.total`).toFixed(2)}`}
                  </small>
                </MuiGrid>
              ) : (
                <MuiGrid
                  item
                  xs={12}
                  component={Warning}
                  color="primary"
                  align="right"
                >
                  Key Handling was not requested
                </MuiGrid>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12} container>
            <Grid item xs={12}>
              <Typography align="right" variant="subtitle2">
                Estimate Subtotal: $
                {getValues('request.subTotal') > 0
                  ? getValues('request.subTotal').toFixed(2)
                  : 0}
              </Typography>
              <Typography align="right" variant="subtitle2">
                HST: $
                {getValues('request.tax') > 0 ? getValues('request.tax') : 0}
              </Typography>
              <Typography align="right" variant="h5" color="secondary">
                Estimate Total: $
                {getValues('request.subTotal') > 0
                  ? getValues('request.grandTotal')
                  : 0}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </MainContainer>
      <Grid item xs={11} container justify="center">
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          size="large"
        >
          Send Request
        </Button>
      </Grid>
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="simple-dialog-title">Request Sent!</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Thank you for reaching out - your request is on its way. You will
            automatically be emailed a copy of your estimate and a staff member
            will be in contact as soon as possible. If you do not receive the
            automatic email please check your spam folder.
            <br />
            <br />> Dayna's Pet Sitting
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </FormContainer>
  );
};
export default Form;
