import React, { useState } from 'react';
import {
  Grid as MuiGrid,
  Radio as MuiRadio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
} from '@material-ui/core';
import styled from 'styled-components';

const Error = styled.small`
  margin: 0;
  padding: 0;
  color: red;
`;

const Grid = styled(MuiGrid)`
  padding: 0.25rem 0.5rem 0;
  width: 100%;
`;

const Keys = ({ cost, register, setValue, updateFormTotal, errors }) => {
  const [radio, setRadio] = useState('no');

  const handleChange = event => {
    setValue(`request.keys.requested`, event.target.value);
    setValue(
      `request.keyHandling.total`,
      event.target.value === 'yes' ? cost * 1 : 0
    );
    setRadio(event.target.value);
    updateFormTotal();
  };

  React.useEffect(() => {
    register({ name: `request.keys.requested` }, { required: true });
    register({ name: `request.keyHandling.total` });
  }, [register]);

  return (
    <Grid item xs={12} container>
      <Grid item xs={12} sm={6}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Add Key Handling Service?</FormLabel>
          <RadioGroup
            row
            aria-label="Add Key Handling Service?"
            name="request.keys.requested"
            value={radio}
            defaultValue="no"
            onChange={handleChange}
          >
            <FormControlLabel
              value="yes"
              control={<MuiRadio />}
              label="Yes"
              labelPlacement="start"
            />
            <FormControlLabel
              value="no"
              control={<MuiRadio />}
              label="No"
              labelPlacement="start"
            />
          </RadioGroup>
        </FormControl>
        {errors?.request?.keys.requested?.type === 'required' && (
          <Error>Handling Service must be answered yes or no</Error>
        )}
      </Grid>
    </Grid>
  );
};
export default Keys;
