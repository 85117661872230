import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { TextField as MuiTextField } from '@material-ui/core';
import styled from 'styled-components';

const TextField = styled(MuiTextField)`
  & fieldset {
    font-size: 1rem;
  }
  & label {
    font-size: 1rem;
    /* color: ${props => props.theme.palette.primary.light}; */
  }
  & input {
    color: ${props => props.theme.palette.primary.main};
  }
`;

const Field = ({
  required,
  name,
  label,
  type,
  defaultValue,
  multiline,
  rowsMax,
  register,
  control,
  rules,
  inputProps,
}) => (
  // const { control } = useForm();
  <>
    <Controller
      required={required}
      inputProps={inputProps}
      as={<TextField />}
      id={name}
      size="small"
      rules={rules || ''}
      variant="outlined"
      defaultValue={defaultValue}
      fullWidth
      name={name}
      label={label}
      type={type}
      multiline={multiline}
      rowsMax={rowsMax}
      control={control}
    />
  </>
);
export default Field;
