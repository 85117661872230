import React, { useState } from 'react';
import {
  Grid as MuiGrid,
  Typography,
  TextField as MuiTextField,
} from '@material-ui/core';
import styled from 'styled-components';

const Error = styled.small`
  margin: 0;
  padding: 0;
  color: red;
`;

const TextField = styled(MuiTextField)`
  & fieldset {
    font-size: 1rem;
  }
  & label {
    font-size: 1rem;
  }
  & input {
    color: ${props => props.theme.palette.primary.main};
  }
`;

const Grid = styled(MuiGrid)`
  padding: 0.25rem 0.5rem 0;
  width: 100%;
`;

const SubTotal = styled(Typography)`
  font-size: 0.6rem;
  color: ${props => props.theme.palette.primary.main};
  font-weight: 400;
  & small {
    font-size: 0.75rem;
    color: ${props => props.theme.palette.secondary.main};
  }
`;

const Animals = ({
  register,
  getValues,
  setValue,
  updateFormTotal,
  errors,
}) => {
  const [total, setTotal] = useState(0.0);

  const handleChange = () => {
    const additionalAnimals = getValues(
      'request.additionalAnimals.numberOfAnimals'
    );
    const cost = () => {
      return additionalAnimals * 3.00
    };
    const newTotal = cost();
    setTotal(newTotal);
    setValue(`request.additionalAnimals.total`, newTotal);
    updateFormTotal();
  };

  React.useEffect(() => {
    register({ name: `request.additionalAnimals.total` });
  }, [register]);

  return (
    <Grid item xs={12} container>
      <Grid
        item
        xs={12}
        md={2}
        component={SubTotal}
        variant="subtitle2"
        align="right"
      >
        Subtotal <small>${total.toFixed(2)}</small>
      </Grid>
      <Grid item xs={12} md={10}>
        <TextField
          size="small"
          color="primary"
          variant="outlined"
          defaultValue="0"
          fullWidth
          name="request.additionalAnimals.numberOfAnimals"
          label="Additional Animals"
          type="number"
          inputRef={register}
          onChange={() => handleChange()}
          rules={{ min: 0 }}
          inputProps={{ min: 0 }}
        />
        {errors?.request?.numberOfNights?.type === 'min' && (
          <Error>Number of animals cannot be a negative number</Error>
        )}
      </Grid>
    </Grid>
  );
};
export default Animals;
