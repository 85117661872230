import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import RequestForm from '../forms/request/form';
import Section from './section';

const OuterContainer = styled.div`
  position: relative;
  left: -2.5%;
  background-color: ${props => props.theme.palette.primary.main};
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25);
  width: 105%;
  padding: 1.5rem 0;
  display: flex;
  align-items: center;
  border-top-left-radius: 50% 2.5rem;
  border-bottom-right-radius: 50% 2.5rem;
  margin-top: -15px;
  overflow: hidden;
`;

// const InnerContainer = styled(BackgroundImage)`
//   background-size: 300px 300px;
//   background-repeat: repeat;
//   /* left: -2.5%; */
//   width: 105%;
//   height: 105%;
//   border-top-left-radius: 50% 2.5rem;
//   border-bottom-right-radius: 50% 2.5rem;
//   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

//   &::before,
//   &::after {
//     background-size: 300px 300px;
//     background-repeat: repeat;
//     /* left: -2.5%; */
//     width: 105%;
//     height: 105%;
//     border-top-left-radius: 50% 2.5rem;
//     border-bottom-right-radius: 50% 2.5rem;
//     box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
//   }
// `;

const InnerContainer = styled.section`
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25);
  left: -2.5%;
  width: 105%;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 4rem 0;
  border-top-left-radius: 50% 2.5rem;
  border-bottom-right-radius: 50% 2.5rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  /* justify-content: center; */
  /* background: rgba(255, 255, 255, 0.9); */
  border-top-left-radius: 50% 2.5rem;
  border-bottom-right-radius: 50% 2.5rem;
`;

const Request = ({ request }) => {
  const data = useStaticQuery(graphql`
    {
      sanityRequestForm {
        heading
        email
        image {
          asset {
            fluid(maxWidth: 800) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      allSanityService {
        nodes {
          name
          pricingSection {
            servicePriceList {
              cost
              duration
              durationType
            }
          }
        }
      }
    }
  `);
  const services = data.allSanityService.nodes;
  const form = data.sanityRequestForm;
  return (
    <OuterContainer id="request">
      <InnerContainer>
        <Overlay>
          <Section>
            <Typography variant="h2" align="center">
              {form.heading}
            </Typography>
            <div>
              <RequestForm />
            </div>
          </Section>
        </Overlay>
      </InnerContainer>
    </OuterContainer>
  );
};
export default Request;
