import React, { useState } from 'react';
import {
  Grid as MuiGrid,
  Typography,
  TextField as MuiTextField,
} from '@material-ui/core';
import styled from 'styled-components';

const TextField = styled(MuiTextField)`
  & fieldset {
    font-size: 1rem;
  }
  & label {
    font-size: 1rem;
  }
  & input {
    color: ${props => props.theme.palette.primary.main};
  }
  margin-bottom: 0.25rem;
`;

const Grid = styled(MuiGrid)`
  padding: 0.25rem 0.5rem 0;
  width: 100%;
`;

const Error = styled.small`
  margin: 0;
  padding: 0;
  color: red;
`;

const SubTotal = styled(Typography)`
  font-size: 0.6rem;
  color: ${props => props.theme.palette.primary.main};
  font-weight: 400;
  & small {
    font-size: 0.75rem;
    color: ${props => props.theme.palette.secondary.main};
  }
`;

const Visit = ({
  option,
  index,
  register,
  getValues,
  setValue,
  updateFormTotal,
  errors,
}) => {
  const [total, setTotal] = useState(0.0);

  const handleChange = () => {
    const visits = getValues(`request.visits[${index}].visitsPerDay`);
    const days = getValues(`request.visits[${index}].numberOfDays`);
    const newTotal = option.cost * visits * days;
    setTotal(newTotal);
    setValue(`request.visits[${index}].total`, newTotal);
    updateFormTotal();
  };

  React.useEffect(() => {
    register({ name: `request.visits[${index}].total` });
    register({ name: `request.visits[${index}].visitLength` });
  }, [index, register]);

  return (
    <Grid item xs={12} container>
      <Grid item xs={12} sm={3} md={3} lg={2}>
        <Typography align="right">{option.label} Visits</Typography>
        <SubTotal color="secondary" variant="subtitle2" align="right">
          Subtotal <small>${total.toFixed(2)}</small>
        </SubTotal>
      </Grid>
      <Grid item xs={12} sm md>
        <TextField
          size="small"
          color="primary"
          variant="outlined"
          defaultValue="0"
          fullWidth
          name={`request.visits[${index}].visitsPerDay`}
          label="Visits Per Day"
          type="number"
          inputRef={register}
          onChange={() => handleChange()}
          rules={{ min: 0 }}
          inputProps={{ min: 0 }}
        />
        {errors?.request?.visitsPerDay?.type === 'min' && (
          <Error>Number of days cannot be a negative number</Error>
        )}
      </Grid>
      <Grid item xs={12} sm md>
        <TextField
          size="small"
          color="primary"
          variant="outlined"
          defaultValue="1"
          fullWidth
          name={`request.visits[${index}].numberOfDays`}
          label="Number Of Days"
          type="number"
          inputRef={register}
          onChange={() => handleChange()}
          rules={{ min: 1 }}
          inputProps={{ min: 1 }}
        />
        {errors?.request?.numberOfDays?.type === 'min' && (
          <Error>Number of days must be greater then 0</Error>
        )}
      </Grid>
    </Grid>
  );
};
export default Visit;
